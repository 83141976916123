@import '../styles/colors';
@import '../styles/utils';

.base {
  border-radius: 3px;
  display: inline-block;
  border: none;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  text-decoration: none !important;
  background: $fog;
  color: $black;
}

.primary {
  background: $white;
  color: $white;
  &:hover {
    color: $white !important;
  }
  &:active,
  &.active,
  &:focus {
    background: shade($white, 10%);
    color: $white;
  }
}

.white-outline {
  box-shadow: inset 0px 0px 0px 1px $white;
  background: transparent;
  color: $white;
  &:hover {
    background: $white;
    color: $black;
  }
  &:active,
  &.active,
  &:focus,
  &.focus {
    background: $cloud;
    color: $black;
  }
}

.secondary {
  background: $fog;
  color: $black;
  &:hover {
    background: tint($fog, 30%);
  }
  &:active,
  &.active,
  &:focus {
    background: shade($fog, 5%);
  }
}

.sm {
  padding: 7px 16px 9px 16px;
  font-size: 16px;
}
.md {
  padding: 8px 18px 6px 18px;
  font-size: 18px;
}
.lg {
  padding: 10px 20px;
  font-size: 20px;
}
