@import '../styles/colors';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.closePhoto {
  color: $pebble;
  cursor: pointer !important;
  position: fixed;
  top: 15px;
  right: 20px;
  @include media-breakpoint-down(sm) {
    top: unset;
    bottom: 15px !important;
  }
  &:hover {
    color: $cloud;
  }
  &:active {
    color: $gray;
  }
}
