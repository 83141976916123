@import '../styles/colors';

.link {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-style: italic;
  text-decoration: none;
  color: $pebble;
  &:hover {
    color: $cloud;
  }
  &:active {
    color: $gray;
  }
}

.activeLink {
  color: $white;
  &:hover {
    color: $white;
  }
}

.navbar {
  transition: background-color 0.2s linear;
}
