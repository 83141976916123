@import './colors';
@import './animations';

// border radius
.br-sm {
  border-radius: 3px;
}
.br-md {
  border-radius: 5px;
}
.br-lg {
  border-radius: 8px;
}
.br-circle {
  border-radius: 1000px;
}

// box shadow
.bs-sm {
  box-shadow: 0 1px 3px 0 transparentize($black, 0.85);
}
.bs-md {
  box-shadow: 0 2px 8px 0 transparentize($black, 0.85);
}
.bs-lg {
  box-shadow: 0 5px 16px 0 transparentize($black, 0.85);
}

.border-top {
  border-top: solid !important;
}

.border-width-2 {
  border-width: 2px !important;
}

.border-color-black,
.border-black {
  border-color: $black !important;
}
.border-color-carbon,
.border-carbon {
  border-color: $carbon !important;
}
.border-color-charcoal,
.border-charcoal {
  border-color: $charcoal !important;
}
.border-color-pebble,
.border-pebble {
  border-color: $pebble !important;
}

.line-height-075 {
  line-height: 0.75 !important;
}

// border
.bt-0 {
  border-top: 0 !important;
}
.bb-0 {
  border-bottom: 0 !important;
}
.bb-2-fog {
  border-bottom: 2px solid $fog !important;
}
.bt-2-fog {
  border-top: 2px solid $fog !important;
}
.b-1-fog {
  box-shadow: inset 0 0 0 1px $fog !important;
}
.br-1-fog {
  border-right: 1px solid $fog !important;
}
.bb-1-cloud {
  border-bottom: 1px solid $cloud;
}
.bb-1-charcoal {
  border-bottom: 1px solid $charcoal;
}
.bt-1-cloud {
  border-top: 1px solid $cloud !important;
}
.bl-1-cloud {
  border-left: 1px solid $cloud !important;
}
.b-1-cloud {
  border: 1px solid $cloud !important;
}
.b-2-cloud {
  border: 2px solid $cloud !important;
}
.br-1-cloud {
  border-right: 1px solid $cloud !important;
}
.by-1-cloud {
  border-top: 1px solid $cloud !important;
  border-bottom: 1px solid $cloud !important;
}
.bb-3-snow {
  border-bottom: 3px solid $snow !important;
}
.bb-3-cloud {
  border-bottom: 3px solid $fog;
}

// font size
.fs-xxxs {
  font-size: 10px !important;
}
.fs-xxs {
  font-size: 14px !important;
}
.fs-xs {
  font-size: 16px !important;
}
.fs-sm {
  font-size: 18px !important;
}
.fs-md {
  font-size: 22px !important;
}
.fs-lg {
  font-size: 32px !important;
}
.fs-xl {
  font-size: 48px !important;
}
.fs-xxl {
  font-size: 64px !important;
}

// font weight
.fw-light {
  font-weight: 300 !important;
}
.fw-regular {
  font-weight: 500 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-black {
  font-weight: 900 !important;
}

// font family
// .ff-nimbus {
//   font-family: $font-stack !important;
// }
// .ff-nimbus-diagonal {
//   font-family: "Nimbus Sans Diagonal", $font-stack !important;
// }

// text alignment
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

// letter spacing
.ls-1 {
  letter-spacing: 1px !important;
}
.ls-2 {
  letter-spacing: 2px !important;
}
.ls-5 {
  letter-spacing: 5px !important;
}

// width
.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}
.w-80 {
  width: 80% !important;
}
.w-70 {
  width: 70% !important;
}
.w-60 {
  width: 60% !important;
}
.w-50 {
  width: 50% !important;
}
.w-40 {
  width: 40% !important;
}
.w-30 {
  width: 30% !important;
}
.w-20 {
  width: 20% !important;
}
.w-10 {
  width: 10% !important;
}

// opacity
.o-100 {
  opacity: 1 !important;
}
.o-90 {
  opacity: 0.9 !important;
}
.o-80 {
  opacity: 0.8 !important;
}
.o-70 {
  opacity: 0.7 !important;
}
.o-60 {
  opacity: 0.6 !important;
}
.o-50 {
  opacity: 0.5 !important;
}
.o-40 {
  opacity: 0.4 !important;
}
.o-30 {
  opacity: 0.3 !important;
}
.o-20 {
  opacity: 0.2 !important;
}
.o-10 {
  opacity: 0.1 !important;
}

// line height
.lh-200 {
  line-height: 2 !important;
}
.lh-50 {
  line-height: 0.5 !important;
}

// text transform
.text-initial {
  text-transform: initial !important;
}

// tint and shade
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// overflows
.o-hidden {
  overflow: hidden;
}
.ox-scroll {
  overflow-x: scroll;
}

// cursor
.c-pointer {
  cursor: pointer !important;
}

// // background colors
// .bg-red {
//   background: $red !important;
// }
// .bg-yellow {
//   background: $yellow !important;
// }
// .bg-orange {
//   background: $orange !important;
// }
// .bg-green {
//   background: $green !important;
// }
// .bg-blue {
//   background: $blue !important;
// }

// // text colors
// .text-red {
//   color: $red !important;
// }
// .text-yellow {
//   color: $yellow !important;
// }
// .text-orange {
//   color: $orange !important;
// }
// .text-green {
//   color: $green !important;
// }
// .text-blue {
//   color: $blue !important;
// }

// background shades
.bg-black {
  background: $black !important;
}
.bg-carbon {
  background: $carbon !important;
}
.bg-charcoal {
  background: $charcoal !important;
}
.bg-pebble {
  background: $pebble !important;
}
.bg-gray {
  background: $gray !important;
}
.bg-cloud {
  background: $cloud !important;
}
.bg-fog {
  background: $fog !important;
}
.bg-snow {
  background: $snow !important;
}
.bg-white {
  background: $white !important;
}

// background black opacity shades
.bg-black-75 {
  background: transparentize($black, 0.25);
}

// text shades
.text-black {
  color: $black !important;
}
.text-carbon {
  color: $carbon !important;
}
.text-charcoal {
  color: $charcoal !important;
}
.text-pebble {
  color: $pebble !important;
}
.text-gray {
  color: $gray !important;
}
.text-cloud {
  color: $cloud !important;
}
.text-fog {
  color: $fog !important;
}
.text-snow {
  color: $snow !important;
}
.text-white {
  color: $white !important;
}

// opacity white text
.text-white-25 {
  color: transparentize($white, 0.75);
}
.text-white-50 {
  color: transparentize($white, 0.5);
}
.text-white-75 {
  color: transparentize($white, 0.25);
}

// opacity white text
.text-black-25 {
  color: transparentize($black, 0.75);
}
.text-black-50 {
  color: transparentize($black, 0.5);
}
.text-black-75 {
  color: transparentize($black, 0.25);
}

.fadein-3s {
  animation: fadein 3s;
}

// // social background colors
// .bg-twitter {
//   background: $twitter !important;
// }
// .bg-facebook {
//   background: $facebook !important;
// }
// .bg-googleplus {
//   background: $googleplus !important;
// }

// // social text colors
// .text-twitter {
//   color: $twitter !important;
// }
// .text-facebook {
//   color: $facebook !important;
// }
// .text-googleplus {
//   color: $googleplus !important;
// }
.user-select-none {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
