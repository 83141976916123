@import '../styles/colors';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.palmLogo {
  width: 40px;
  @include media-breakpoint-down(sm) {
    width: 35px;
  }
}

.ylSerifLogo {
  width: 300px;
  @include media-breakpoint-down(sm) {
    width: 250px;
  }
}

.window {
  height: 150px;
  width: 100%;
  background: url("../assets/images/releases/ghost/ghost-4.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
