@import './colors';
@import './fonts';
@import './utils';
@import './bg_utils';
@import './custom';
@import './litd';

body {
  font-family: $font-stack;
  background: $black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none !important;
}

::selection {
  background: $white;
  color: $black;
}

.hr {
  border: unset !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  width: 100%;
}

.hr-gray {
  border-bottom: 2px solid $gray !important;
}
.hr-cloud {
  border-bottom: 2px solid $cloud !important;
}
.hr-white {
  border-bottom: 2px solid $white !important;
}

.youtube-embed {
  width: 100%;
  height: 624px;
}

.link {
  color: $pebble;
  &:hover {
    color: $cloud;
  }
  &:active {
    color: $gray;
  }
}

.link {
  color: $gray;
  &:hover {
    color: $cloud;
  }
  &:active {
    color: $gray;
  }
}
