$black: #000000; // hsl(220,00%,00%)
$carbon: #0a0c10; // hsl(220,25%,05%)
$charcoal: hsl(220, 20%, 15%);

$pebble: hsl(220, 12%, 35%);
$gray: hsl(220, 12%, 65%);
$cloud: hsl(220, 12%, 85%);

$fog: hsl(220, 12%, 95%);
$snow: hsl(220, 12%, 98%);
$white: hsl(220, 12%, 100%);

$white-25: rgba(255, 255, 255, 0.25);
$white-50: rgba(255, 255, 255, 0.5);
$white-75: rgba(255, 255, 255, 0.75);

$blue: #3ce1ff;
$gold: #f0ce77;
