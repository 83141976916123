.playerWrapper {
  position: relative;
  padding-top: 42.4%;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}
