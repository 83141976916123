@import 'colors';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.bg-ghost-1 {
  background: url('../assets/images/releases/ghost/ghost-1.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-ghost-2 {
  background: url('../assets/images/releases/ghost/ghost-2.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-ghost-3 {
  background: url('../assets/images/releases/ghost/ghost-3.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-ghost-4 {
  background: url('../assets/images/releases/ghost/ghost-4.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-ghost-5 {
  background: url('../assets/images/releases/ghost/ghost-5.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-ghost-6 {
  background: url('../assets/images/releases/ghost/ghost-6.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-ghost-7 {
  background: url('../assets/images/releases/ghost/ghost-7.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-ghost-8 {
  background: url('../assets/images/releases/ghost/ghost-8.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($carbon, 0.2);
}

.bg-tempe-2 {
  background: url('../assets/images/backgrounds/tempe_2.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-tempe {
  background: url('../assets/images/backgrounds/tempe.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.5);
}
.bg-palms {
  background: url('../assets/images/backgrounds/palms.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    box-shadow: inset 0px 0px 0px 10000px $black;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.75);
}
.bg-stairs {
  background: url('../assets/images/backgrounds/stairs.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  // background-size: 75%;
  box-shadow: inset 0px 0px 0px 10000px transparentize($carbon, 0.07);
}
.bg-after-you {
  background: url('../assets/images/backgrounds/after_you.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  // background-size: 75%;
  box-shadow: inset 0px 0px 0px 10000px transparentize($carbon, 0.45);
}
.bg-black-80 {
  background: transparentize($black, 0.2) !important;
}
.bg-black-70 {
  background: transparentize($black, 0.3) !important;
}
.bg-black-60 {
  background: transparentize($black, 0.4) !important;
}
.bg-black-50 {
  background: transparentize($black, 0.5) !important;
}
.bg-black-40 {
  background: transparentize($black, 0.6) !important;
}
.bg-passionate {
  background: url('../assets/images/backgrounds/passionate.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-in-due-time {
  background: url('../assets/images/backgrounds/in_due_time.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.3);
}
.bg-pressure {
  background: url('../assets/images/backgrounds/pressure.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.15);
}
.bg-poolside {
  background: url('../assets/images/backgrounds/poolside.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.15);
}
.bg-ride {
  background: url('../assets/images/backgrounds/ride.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.15);
}
.bg-palm-trees {
  background: url('../assets/images/backgrounds/palm_trees.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.5);
}
.bg-come-over {
  background: url('../assets/images/backgrounds/come_over.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 0px 10000px transparentize($black, 0.5);
}

.bg-litd {
  background: url('../assets/images/backgrounds/litd.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-hours {
  background: url('../assets/images/backgrounds/hours.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-arizona-nights {
  background: url('../assets/images/backgrounds/arizona_nights.png');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-hotel {
  background: url('../assets/images/backgrounds/hotel.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-dark-places {
  background: url('../assets/images/backgrounds/dark_places.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-the-worst {
  background: url('../assets/images/backgrounds/the_worst.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-high-up {
  background: url('../assets/images/backgrounds/high_up.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-clouds {
  background: url('../assets/images/backgrounds/clouds.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-sedona {
  background: url('../assets/images/backgrounds/sedona.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}

.bg-welcome-to-the-party {
  background: url('../assets/images/backgrounds/welcome_to_the_party.jpg');
  background-attachment: fixed;
  @include media-breakpoint-down(sm) {
    background-attachment: scroll;
  }
  background-size: cover;
  background-position: center;
}
