.litd-container {
  /* width: 200px;
  height: 260px; */
  /* border: 1px solid #ccc; */
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
}
.album-cover {
  border: 1px solid #f3d19a;
  position: relative;
  cursor: pointer;
  width: 600px;
  height: 600px;
  @include media-breakpoint-down(sm) {
    width: 300px;
    height: 300px;
  }
  -webkit-transition: -webkit-transform 1s;
  -moz-transition: -moz-transform 1s;
  -o-transition: -o-transform 1s;
  transition: transform 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
}

.album-cover img {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* height: 100%;
  width: 100%; */
  /* line-height: 260px; */
  /* color: white; */
  /* text-align: center; */
  /* font-weight: bold; */
  /* font-size: 140px; */
  /* position: absolute; */
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
.album-cover .cover-back {
  /* background: blue; */
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.album-cover.flipped {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.litd-album-name {
  max-width: 350px;
  @include media-breakpoint-down(sm) {
    max-width: 265px;
  }
  width: 100%;
}
